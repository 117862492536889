<template>
    <k-layout :navProps="{ variant: 'dark' }">
        <div class="page">
            <div class="tw-bg-gray-900 tw-py-24">
                <k-container>
                    <h1 class="tw-heading-1 tw-text-gray-50">{{ $t('referral.title') }}</h1>
                </k-container>
            </div>
            <div id="success-section"></div>
            <div v-if="formState === 'SUCCESS'" class="container">
                <div class="card card-submitted">
                    <img class="card-submitted__icons" src="./icons/ok.svg" alt="Hotovo" />
                    <h2 class="medium-heading-text">{{ $t('global.titles.all_done') }}</h2>
                    <p class="px-8 mt-4 body-text">
                        {{ $t('referral.descriptions.recommendation_touch_soon') }}
                    </p>
                    <k-button id="ga_referral_navigate-home" href="/" class="mt-8" width="132">
                        {{ $t('navigation.home') }}
                    </k-button>
                </div>
            </div>

            <k-container v-else class="tw-mt-12">
                <template #default>
                    <v-form v-model="valid" ref="form" class="tw-bg-white tw-p-8">
                        <form @submit.prevent="submitForm">
                            <h4 class="tw-heading-4">{{ $t('referral.subtitles.candidate_information') }}</h4>
                            <hr class="kod-hr" />

                            <p class="mt-6 title-text">{{ $t('referral.descriptions.recommend_to_position') }}</p>

                            <v-radio-group hide-details v-model="referralToSpecificPosition" row style="padding: 0;">
                                <k-radio value="yes" :label="$t('global.yes')" />
                                <k-radio value="no" :label="$t('global.no')" class="ml-6" />
                            </v-radio-group>

                            <k-autocomplete
                                class="mt-4"
                                :invalid="submitted && !form.candidate.project"
                                v-if="referralToSpecificPosition === 'yes'"
                                v-model="selectedProject"
                                :rules="referralToSpecificPosition === 'yes' ? [formRules.required] : []"
                                :items="projectItems"
                                :label="$t('global.actions.select')"
                                cache-items
                                item-text="name"
                                item-value="id"
                            >
                                <template v-slot:item="data">
                                    <p class="body-text">
                                        {{ data.item.name }}
                                        <small>&bull; {{ data.item.location }}</small>
                                    </p>
                                </template>

                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                            </k-autocomplete>

                            <div class="mt-8 form-grup">
                                <k-input
                                    v-model="form.candidate.name"
                                    :rules="[formRules.required]"
                                    :emptyNotice="submitted"
                                    type="text"
                                    :title="$t('global.inputs.firstname_surname')"
                                >
                                    <template #message="{ message }">
                                        {{ $t(message) }}
                                    </template>
                                </k-input>
                                <k-input
                                    v-model="form.candidate.email"
                                    :rules="[formRules.required, formRules.email]"
                                    :emptyNotice="submitted"
                                    type="email"
                                    :title="$t('global.inputs.email')"
                                >
                                    <template #message="{ message }">
                                        {{ $t(message) }}
                                    </template>
                                </k-input>
                            </div>

                            <div class="mt-8 form-grup form-grup--phone">
                                <k-input
                                    v-model="form.candidate.phone"
                                    :rules="[formRules.required]"
                                    :emptyNotice="submitted"
                                    type="text"
                                    :title="$t('global.inputs.phone')"
                                >
                                    <template #message="{ message }">
                                        {{ $t(message) }}
                                    </template>
                                </k-input>
                                <k-input
                                    v-model="form.candidate.currentPosition"
                                    :rules="[formRules.required]"
                                    :emptyNotice="submitted"
                                    type="text"
                                    :title="$t('referral.inputs.current_position')"
                                >
                                    <template #message="{ message }">
                                        {{ $t(message) }}
                                    </template>
                                </k-input>
                            </div>

                            <h4 class="mt-12 tw-heading-4">{{ $t('referral.subtitles.information_about_you') }}</h4>
                            <hr class="kod-hr" />

                            <div class="mt-6 form-grup">
                                <k-input
                                    v-model="form.contact.name"
                                    :rules="[formRules.required]"
                                    :emptyNotice="submitted"
                                    type="text"
                                    :title="$t('global.inputs.firstname_surname')"
                                >
                                    <template #message="{ message }">
                                        {{ $t(message) }}
                                    </template>
                                </k-input>
                                <k-input
                                    v-model="form.contact.email"
                                    :rules="[formRules.required, formRules.email]"
                                    :emptyNotice="submitted"
                                    type="email"
                                    :title="$t('global.inputs.email')"
                                >
                                    <template #message="{ message }">
                                        {{ $t(message) }}
                                    </template>
                                </k-input>
                            </div>

                            <p class="mt-8 title-text">{{ $t('referral.descriptions.preferred_type_of_provision') }}</p>
                            <p class="body-text">{{ $t('referral.descriptions.change_provision_type') }}</p>
                            <v-radio-group hide-details v-model="form.contact.commission" row style="padding: 0;">
                                <k-radio value="one-time" :label="$t('referral.one_time_1000')" />
                                <k-radio value="periodic" :label="$t('referral.per_day_worked_5')" class="radio-periodic" />
                            </v-radio-group>

                            <h4 class="mt-12 tw-heading-4">{{ $t('gdpr.title') }}</h4>
                            <hr class="kod-hr" />

                            <k-gdpr
                                class="mt-6"
                                :showNewsletter="false"
                                v-model="form.contact.gdpr"
                                :invalid="submitted && (!form.contact.gdpr || !form.contact.gdpr.gdprValue)"
                                :gdprTooltip="$t('gdpr.descriptions.privacy_policy_tooltip')"
                            />

                            <hr class="kod-hr kod-hr--mt-8" />

                            <v-expand-transition>
                                <div v-show="submitted && !isFormValid()" class="mb-8 form-error-message">
                                    <p>{{ $t('global.form_errors.check_entered_information') }}</p>
                                </div>
                            </v-expand-transition>

                            <div class="mt-8 submit-button-wrapper">
                                <k-button
                                    id="ga_referral_submit"
                                    :block="isMobile"
                                    :loading="formState === 'PENDING'"
                                    type="submit"
                                >
                                    {{ $t('global.actions.send') }}
                                </k-button>
                            </div>
                        </form>
                    </v-form>
                </template>

                <template #aside>
                    <div class="tw-bg-white tw-p-8">
                        <h4 class="tw-heading-4">{{ $t('referral.subtitles.you_should_know') }}</h4>
                        <hr class="kod-hr" />
                        <p class="mt-4 body-text" v-html="$t('referral.descriptions.forms_of_reward')" />
                        <p class="mt-4 body-text">
                            {{ $t('referral.descriptions.shorter_projects') }}
                        </p>
                        <p class="mt-4 body-text">
                            {{ $t('referral.descriptions.receive_commission') }}
                        </p>
                    </div>
                </template>
            </k-container>
        </div>
    </k-layout>
</template>

<script>
import colors from "../sass/variables.scss";
import responsiveMixin from "./../responsiveMixin";

export default {
    mixins: [responsiveMixin],
    mounted() {
        this.$store.dispatch("REFERRAL/LOAD_PROJECTS");
        this.preselectProject();
    },
    data() {
        const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

        return {
            valid: false,
            submitted: false,
            selectedProject: null,
            formRules: {
                required: v => !!v || "global.form_rules.non_empty_field",
                email: v => (v && emailRe.test(v)) || "global.form_rules.valid_email"
            },
            searchProjectsQuery: null,
            referralToSpecificPosition: "no",
            form: {
                candidate: {
                    project: null,
                    name: null,
                    email: null,
                    phone: null,
                    currentPosition: null
                },
                contact: {
                    name: null,
                    email: null,
                    commission: null,
                    gdpr: null
                }
            },
            pageObject: {
                headerObject: {
                    heading: "Koderia Referral",
                    containerClass: "standard",
                    breadcrumbs: []
                }
            }
        };
    },
    computed: {
        profile() {
            return this.$store.state.USER.profile || null;
        },
        projectItems: function() {
            const projects = this.$store.state.REFERRAL.projects;
            return !Array.isArray(projects) ? [] : projects;
        },
        loadingProjects: function() {
            return false;
        },
        formState: function() {
            return this.$store.state.REFERRAL.formState;
        },
        colors: function() {
            return {
                kodGreen: colors.kodGreen
            };
        }
    },
    watch: {
        formState() {
            if (this.formState === "SUCCESS") {
                this.$nextTick(() => {
                    this.$vuetify.goTo("#success-section");
                });
            }
        },
        selectedProject: function(projectId) {
            this.$set(this.form.candidate, "project", projectId);
        },
        projectItems: function() {
            this.preselectProject();
        },
        profile: {
            immediate: true,
            handler() {
                if (this.profile && this.profile.email) {
                    this.form.contact.email = this.profile.email;
                }

                if (this.profile && this.profile.firstName) {
                    if (this.profile.lastName) {
                        this.form.contact.name = this.profile.firstName + " " + this.profile.lastName;
                    } else {
                        this.form.contact.name = this.profile.firstName;
                    }
                }
            }
        }
    },
    methods: {
        preselectProject: function() {
            const projectId = this.$route.params.id;
            if (projectId && this.projectItems) {
                const project = this.projectItems.find(p => p.id === projectId);
                if (project) {
                    this.referralToSpecificPosition = "yes";
                    this.selectedProject = project;
                }
            }
        },
        isFormValid: function() {
            return this.valid && this.form.contact.gdpr.gdprValue;
        },
        submitForm() {
            this.submitted = true;
            this.$refs.form.validate();

            if (!this.isFormValid()) {
                return;
            }

            this.$store.dispatch("REFERRAL/SUBMIT_REFERRAL", this.form);
            window.location.hash = "#referral-hotovo";
        }
    }
};
</script>

<style scoped lang="scss">
.page {
    font-family: inherit;
    font-size: 1rem;
    color: $koderia-black;
    height: 100%;
    margin-bottom: 8rem;
}

.page-content {
    @include margin-top-multiplier(5);
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 39rem 19.5rem;

    @media (max-width: 63.938rem) {
        margin: 0 auto;
        max-width: 39rem;
        grid-template-columns: 1fr;
    }
}

aside {
    .title-text {
        @include margin-bottom-multiplier(0);
    }
}

.form-grup {
    display: grid;
    // grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;

    @media (max-width: 63.938rem) {
        grid-template-columns: 1fr;
    }
}

.form-grup--phone {
    grid-template-columns: 12.25rem auto;
    @media (max-width: 63.938rem) {
        grid-template-columns: 1fr;
    }
}

.footer {
    @include padding-top-multiplier(20);
}

.card {
    @include padding-multiplier(4);
    @media (max-width: 63.938rem) {
        @include padding-multiplier(2);
        @include padding-top-multiplier(3);
    }
}

.card-submitted {
    @include margin-top-multiplier(5);
    @include margin-bottom-multiplier(24);
    @include padding-multiplier(5);
    max-width: 39rem;
    margin: 0 auto;
    text-align: center;
}

.card-submitted__icons {
    width: 4.5rem;
    height: 4.5rem;
}

.submit-button-wrapper {
    text-align: center;
}

.kod-hr {
    margin-top: 0.5rem !important;
}

.kod-hr--mt-8 {
    margin-top: 2rem !important;
}
.radio-periodic {
    margin-left: 1.5rem;
    @media (max-width: 63.938rem) {
        margin-top: 0.5rem;
        margin-left: 0;
    }
}
</style>
